import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { Tooltip, OverlayTrigger, Form, Button } from "react-bootstrap";
import { Widget, addResponseMessage, toggleWidget } from 'react-chat-widget';
import ReactCharacterApiClient from '@mediasemantics/react-character-api-client';
import SidePanel from './SidePanel.js';
import TopBar from './TopBar.js';
import ContactModal from './ContactModal.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './chat-styling.css'; // from 'react-chat-widget/lib/styles.css';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';

//const API_BASE = "http://localhost:3000/";
const API_BASE = "https://ioffice.live/api/";

const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

class App extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {env:null, contact:false, calendar:false, app:false, tick:0, input:"", slide1:false, radio:undefined, menu:false, placeholder:"Text Me!"}

        this.calendar = React.createRef();
        this.agent = React.createRef();

        this.idleTimeout = null;
        this.idleCount = 0;
        this.nextIdle = undefined;

        // If you DON'T have a login/authentication system, then this  mechanism can at least give
        // you a stable userid that lets you remember a user from login to login, provided that they
        // login from the same device. Please refer also to privacy laws governing the use of cookies.
        this.userid = this.getCookie("userid");
        // Otherwise we generate our own using the timestamp and some random digits
        if (!this.userid) {
            this.userid = (new Date()).getTime().toString();
            for (var i = 0; i <= 3; i++)
                this.userid += Math.floor(Math.random()*10);
            this.setCookie("userid", this.userid, 365);
        }

        this.calendar = React.createRef();
        this.widgetOpen = false;
        this.environmentLoaded = false;
        this.agentLoaded = false;
        this.started = false;

        this.recognition = null;         // reco object
        this.recognizing = false;        // whether the recognizer is active
        this.final_transcript = '';      // final transcript
        this.spinner = undefined;        // for mic spinner
        this.ctx = undefined;
        this.w = undefined;
        this.h = undefined;
        this.rad = undefined; 
        this.phi = undefined; 
        this.size = undefined; 
        this.r = undefined; 
    }  

    render() {
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
              Calendar Demo
            </Tooltip>
          );
        let today = new Date().toISOString().slice(0, 10);
        //console.log(today);
        return (
            <div style={{background:"#000000", width:"100vw", height:"100vw"}}>

                {true/*!this.state.app*/ && 
                <div id="frame" style={{position:"relative", width:"936px", height:"520px"}}>

                    {/* Top Bar */}
                    <div style={{position:"absolute", left:"160px", width:`${776/0.6}px`, height:`${25/0.6}px`, background:"#f4f3ef", transform:"scale(0.6)", transformOrigin:"top left"}}>
                        <TopBar/>
                    </div>

                    {/* Background image */}
                    <div style={{position:"absolute", left:"160px", top:"25px", backgroundImage:'url("img/bg2k.jpg")', width:"776px", height:"480px", backgroundPosition:"center", backgroundSize:"cover"}} >
                    </div>

                    {/* Name tag - positions based on a 776x465 office image */}
                    <div style={{position:"absolute", left:(160+516)+"px", top:(25+424)+"px", color:"#ffffff", fontFamily:"Arial", fontSize:"22px", width:"190px", display:"flex", justifyContent:"center", cursor:"pointer"}} onClick={this.handleNameplateClick.bind(this)}>
                        {this.state.env && this.state.env.first ? this.state.env.first + " " + this.state.env.last : ""}
                        {!(this.state.env && this.state.env.first) &&
                            <>
                            RACHEL&nbsp;<p style={{fontSize:"12px", marginTop:"7px"}}>(CLICK HERE)</p>
                            </>
                        }
                    </div>

                    {/* Time in Clock */}
                    <div style={{position:"absolute", left:(160+433)+"px", top:(25+47)+"px", color:"#808080", fontFamily:"Arial", fontSize:"12px", width:"30px", display:"flex", justifyContent:"center", transform:"rotate(0deg)", transformOrigin:"center"} } > {/* note 2deg aligns text with clock but it makes everything look crooked */}
                        {(new Date()).getUTCHours() + ":" + ("0" + (new Date()).getUTCMinutes().toString()).substr(-2)}
                    </div>

                    {/* Time Hotspot */}
                    <div style={{position:"absolute", left:(160+433)+"px", top:(25+47)+"px", width:"30px", height:"20px", cursor:"pointer"}} onClick={this.handleTimeClick.bind(this)}>
                    </div>

                    {/* Date in Calendar */}
                    <div style={{position:"absolute", left:(160+415)+"px", top:(25+99)+"px", color:"#808080", fontFamily:"Arial", fontSize:"12px", width:"65px", display:"flex", justifyContent:"center"}} >
                        {month[(new Date()).getUTCMonth()]}
                    </div>

                    {/* Calendar Hotspot */}
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                    <div style={{position:"absolute", left:(160+415)+"px", top:(25+99)+"px", width:"65px", height:"60px", cursor:"pointer"}} onClick={this.handleCalendarClick.bind(this)}>
                    </div>
                    </OverlayTrigger>

                    {/* Copyright */}
                    <div style={{position:"absolute", left:(160+500)+"px", top:(25+483)+"px", color:"#ffffff", fontFamily:"Arial", fontSize:"8px", width:"300px", display:"flex", justifyContent:"left"}} >
                        iOffice © Copyright 2024 Greenportal L.L.C. All rights reserved.
                    </div>

                    {/* Chatbox */}
                    <div style={{position:"absolute", left:(160+470)+"px", top:(25+350)+"px", width:"350px", height:"75px", transform:"scale(0.8)", transformOrigin:"top left", backgroundColor:"white", borderStyle:"solid", borderWidth:"3px",
                        boxShadow:"0 4px 16px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} >
                        <textarea rows={1} className="rachelChat" value={this.state.input} style={{width:"300px", border:"none", paddingLeft:"10px"}} onChange={(e)=>this.setState({input:e.target.value})} placeholder={this.state.placeholder} 
                            onFocus={this.handleChatFocus.bind(this)} onKeyDown={this.handleChatKeyDown.bind(this)}/>
                    </div>
                    <img src="img/RachelHead.png" style={{ position:"absolute", left:(160+720)+"px", top:(25+335)+"px", width:`${204*0.2}px`, height:`${208*0.2}px`, cursor:"pointer"}} onClick={this.handleRachelClick.bind(this)}></img>
                    { 'webkitSpeechRecognition' in window && 
                        <div id="mic" style={{width:"50px", height:"50px", position:"absolute", left:(160+470)+"px", top:(25+370)+"px", cursor:"pointer", transform:"scale(0.8)", transformOrigin:"top left"}}>
                            <div style={{width:"50px", position:"relative"}}>
                                <div id="micbtn" style={{position:"absolute", top:"9px", left:"17px", color:"#aaaaaa", transform:"scale(1.4)", transformOrigin:"top left",}}
                                   onClick={this.startStopRecording.bind(this)}>
                                    <FontAwesomeIcon icon={faMicrophone}/>
                                </div>
                                <div style={{position:"absolute", top:"0px", left:"0px", width:"50px", height:"50px", pointerEvents:"none"}}>
                                    <canvas id="micspin" width="50" height="50" style={{width:"50px", height:"50px"}}></canvas>
                                </div>
                            </div>
                        </div>
                    }
                    <div style={{position:"absolute", left:(160+723)+"px", top:(25+380)+"px", cursor:"pointer", color:"#aaaaaa"}} onClick={this.handleChatSubmit.bind(this)}>
                        <FontAwesomeIcon icon={faPaperPlane}/>
                    </div>

                    {/* Side Panel */}
                    <div style={{ position:"absolute", width:`${160/0.8}px`, height:'100vw', transform:"scale(0.8)", transformOrigin:"top left"}}>
                        <SidePanel onClick={this.handleSidePanelClick.bind(this)} collapsed={false}/>
                    </div>

                    {/* Solutions Partner Logo */}
                    <div style={{ position:"absolute", left:"25px", top:"450px", width:`${75/0.8}px`, transform:"scale(0.8)", transformOrigin:"top left"}}>
                        <img src="img/MSSolutionsPartner.gif" style={{width:"75px", height:"58px"}}/>                    
                    </div>

                    {/* Contact modal */}
                    {this.state.contact &&
                    <div style={{backgroundColor:"#ffffff", position:"absolute", left:"400px", top:"50px", transform:"scale(0.6)", transformOrigin:"top left", width:`${500/0.6}px`, height:`${300/0.6}px`, padding:"10px", boxShadow:"0 4px 16px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                        <ContactModal submitCompleteHandler={this.handleSubmitComplete.bind(this)}/>
                    </div>
                    }      

                    {this.state.calendar &&
                    <div style={{backgroundColor:"#ffffff", position:"absolute", left:"400px", top:"50px", transform:"scale(0.6)", transformOrigin:"top left", width:`${500/0.6}px`, height:`${300/0.6}px`, padding:"10px", boxShadow:"0 4px 16px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>

                        <FullCalendar
                            ref={this.calendar}
                            height={290/0.6+"px"}
                            plugins={[ timeGridPlugin, bootstrapPlugin ]}
                            initialView="timeGridWeek"
                            weekends={false}
                            headerToolbar={{
                                left: 'prev,next',
                                center: 'title',
                                right: ''
                            }}
                            slotMinTime="09:00:00"
                            allDaySlot={false}
                            events={[
                                { title: "30 min", startTime: '09:00:00', endTime: '09:30:00', startRecur: today, endRecur: '2024-10-1'},
                                { title: "30 min", startTime: '09:30:00', endTime: '10:00:00', startRecur: today, endRecur: '2024-10-1'},
                                { title: "30 min", startTime: '10:00:00', endTime: '10:30:00', startRecur: today, endRecur: '2024-10-1'},
                                { title: "30 min", startTime: '10:30:00', endTime: '11:00:00', startRecur: today, endRecur: '2024-10-1'},
                                { title: "30 min", startTime: '11:00:00', endTime: '11:30:00', startRecur: today, endRecur: '2024-10-1'},
                                { title: "30 min", startTime: '11:30:00', endTime: '12:00:00', startRecur: today, endRecur: '2024-10-1'},
                            ]}
                            themeSystem="bootstrap5"
                            eventClick={this.handleEventClick.bind(this)}
                        />
                        {/* themeSystem: 'bootstrap5' */}

                    </div>
                    }      

                    {this.state.slide1 &&
                    <div style={{ position:"absolute", left:"486px", top:"75px", width:`${(491*0.8)/0.8}px`, transform:"scale(0.8)", transformOrigin:"top left", boxShadow:"0 4px 16px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                        <img src="img/slide1.jpeg" style={{width:"491px"}}/> 
                    </div>
                    }              

                    {this.state.menu &&
                    <div style={{ position:"absolute", left:"486px", top:"75px", backgroundColor:"white", padding:"5px", width:`${(491*0.8)/0.8}px`, transform:"scale(0.8)", transformOrigin:"top left", boxShadow:"0 4px 16px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                        <center>
                        <img src="img/iOfficeConnectLogo.png" style={{width:"200px"}}/>
                        <p><big><b>Demonstration Selector</b></big></p>
                        </center>
                        <p><small>Text or click to select a demonstration:</small></p>
                        <Form.Group style={{paddingLeft:"15px"}}>
                        <Form.Check type={'radio'} name="demo" checked={this.state.radio == 1} onChange={e=>{this.setState({radio:1},()=>this.handleGoClick())}} label={`AI Powered Expert Systems`} id={`expertBtn`} />
                        <Form.Check type={'radio'} name="demo" checked={this.state.radio == 2} onChange={e=>{this.setState({radio:2},()=>this.handleGoClick())}} label={`Conversational AI`} id={`chatBtn`} />
                        <Form.Check type={'radio'} name="demo" checked={this.state.radio == 3} onChange={e=>{this.setState({radio:3},()=>this.handleGoClick())}} label={`Organizational Transformation`} id={`transformationBtn`} />
                        <Form.Check type={'radio'} name="demo" checked={this.state.radio == 4} onChange={e=>{this.setState({radio:4},()=>this.handleGoClick())}} label={`Customer Acquisition`} id={`customerBtn`} />
                        <Form.Check type={'radio'} name="demo" checked={this.state.radio == 5} onChange={e=>{this.setState({radio:5},()=>this.handleGoClick())}} label={`Other`} id={`otherBtn`} />
                        </Form.Group>
                        {/*
                        <Button onClick={this.handleGoClick.bind(this)}>Go</Button>
                        */}
                        <br/>
                    </div>
                    }              

                </div>
                }

                {/* Rachel Head */}
                <ReactCharacterApiClient id="myAgent" ref={this.agent} style={{
                    position:"absolute", 
                    transformOrigin: "top left",
                    pointerEvents: "none",
                    zIndex:"4",
                    top:"0", 
                    left:"100px", 
                    width:"750px", 
                    height:"600px"}}
                    animateEndpoint={API_BASE + "animate"}
                    catalogEndpoint={API_BASE + "animate"}
                    character="IOFRachelHead"
                    density={3}
                    version={0.1}
                    format="png"
                    idleData={{"normal":["idle1-18"]}}
                    voice="GenerativeRuth"
                    cache="54124"
                    visible={false}
                    onCharacterLoaded={()=>{
                        this.agentLoaded = true;
                        this.preload();
                    }}
                    onScriptCommand={(e)=>{
                        this.handleCommand(e.detail);
                    }}
                    onPlayComplete={(e)=>{
                        if (this.agent.current.visible()) this.startIdle();
                    }}
                />

                {/* Chat */}
                {/*
                <Widget 
                    handleNewUserMessage={this.reply.bind(this)}
                    handleToggle={this.toggle.bind(this)}
                    title="Talk to Rachel"
                    subtitle="Your iOffice guide."
                    autofocus={true}
                    emojis={true} />
                */}
                
            </div>
        );
    }

    preload() {
        // Important: if you change the opening line at all then you should replicate this here.
        this.agent.current.preloadDynamicPlay({do:"greet", say:"Welcome. In this demonstration I will show how conversational AI engages people. I can help complete forms. Select options. And guide team work."})
    }

    renderRetract() {
        return ""//"FirstName(User,?x)^LastName(User,?y)^Sees(User,?a)^Slot(?b)^Time(?c,?d)";
    }   
    
    renderAssert() {
        let s = '';
        //if (this.state.env && this.state.env.first) 
        //    s += `FirstName(User,"${this.state.env.first}")^LastName(User,"${this.state.env.last}")`;
        /*
        let n = 1;
        for (let h = 9; h <= 12; h++) {
            for (let m = 0; m <= 1; m++) {
                s += (s ? '^' : '') + `Sees(User,s${n})^Slot(s${n})^Time(s${n},"${h}:00")`;
                n++;
                s += (s ? '^' : '') + `Sees(User,s${n})^Slot(s${n})^Time(s${n},"${h}:30")`;
                n++;
            }
        }
        */
        return s;
    }

    componentDidMount() {
        let preloads = [
            "img/slide1.jpeg",
            "img/iOfficeConnectLogo.png"
        ]
        preloads.forEach((preload) => {
            const img = new Image();
            img.src = preload;
        });

        this.resizeFrame();
        this.resizeAgent();
        window.addEventListener("resize", ()=>{
            document.body.style.overflow = "hidden";
            this.resizeFrame();
            this.resizeAgent();
        });
        setTimeout(() => {
            document.body.style.overflow = "hidden"; // HACK!
        }, 0);
        setInterval(() => {
            this.setState({tick: this.state.tick + 1});
        }, 1000);
        this.loadEnvironment();
    }

    componentDidUpdate() {
        this.resizeFrame();
    }

    loadEnvironment() {
        let o = App.searchToObject();
        //console.log(o.desk); // we started out by having tom assign ioffice.live?desk=123 type urls...
        fetch(API_BASE + "environment?userid=" + this.userid).then(response => response.json()).then(data => {
            if (!data.success) {
                if (data.message == "unknown user") {
                    // proceed
                }
                else throw new Error(data.message);
            }
            this.setState({env:data});
            this.loadEnvironmentComplete();
        }).catch(e => {
            console.log(e.message||"no service");
        });
    }

    loadEnvironmentComplete() {
        //this.clickHandler = this.handlePlayshieldClick.bind(this);
        //document.body.addEventListener("click", this.clickHandler);
        this.environmentLoaded = true;
    }

    /*
    handlePlayshieldClick() {
        // Standard chatbot behavior is to stay closed until requested, but send [autostart] (i.e. [new]/[return] so agent can start off with a red badge)
        // We need to ensure we get one click, for the audio. In this app we start closed, but ANY key will open us up (similar to a playshield). Should we put a play shield?
        document.body.removeEventListener("click", this.clickHandler);
        this.clickHandler = null;
        this.openChat(); // causes it to show
        this.reply("[open]"); 
    }
    */

    handleCalendarClick() {
        window.location.href = "https://connect.ioffice.us/webtab/539841000000272001"; //"https://connect.ioffice.us/group/a-team/webtab/539841000000220060";
        //this.setState({calendar:true});
    }   

    handleTimeClick() {
        window.location.href = "https://connect.ioffice.us/home/customapps/customapp/time-tracker-2--4466313000000095003";
    }

    handleChatFocus() {
        this.setState({placeholder:""});
        if (this.environmentLoaded && this.agentLoaded && !this.started) this.start();
    }

    handleNameplateClick() {
        if (this.environmentLoaded && this.agentLoaded && !this.started) this.start();
    }

    handleRachelClick() {
        if (this.environmentLoaded && this.agentLoaded && !this.started) this.start();
    }    

    handleChatKeyDown(e) {
        this.resetIdle();
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleChatSubmit();
        }
    }

    handleChatSubmit() {
        let text = this.state.input;
        this.setState({input:""})
        this.reply(text);
    }    

    start() {
        this.started = true;
        this.agent.current.show();//fadeIn();
        this.reply("[open]"); 
        this.startIdle();
    }

    static searchToObject() {
        let pairs = window.location.search.substring(1).split("&"), obj = {}, pair, i;
        for ( i in pairs ) {
          if (pairs[i] === "") continue;
          pair = pairs[i].split("=");
          obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
        }
        return obj;
    }

    handleGoClick(event) {
        let map = {
            1: "expert systems",
            2: "conversational ai",
            3: "organizational transformation",
            4: "customer acquisition",
            5: "other"
        }
        this.reply(map[this.state.radio]);
    }

    handleEventClick(event) {
        let start = event.event.start;
        //console.log(start.toISOString()); //2024-05-13T13:00:00.000Z

        let daysOfTheWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let day = daysOfTheWeek[start.getDay()];
        let time = start.toLocaleTimeString('en-US').replace(/:00/g, ""); // 9:00:00 AM -> 9 AM and 9:30:00 AM -> 9:30 AM

        let input = `[click ${start.toISOString()} ${day} ${time}]`;
        this.reply(input);
        this.resetIdle();
    }

    handleSidePanelClick(location) {
        if (location == "contact") {
            this.setState({contact:!this.state.contact});
        }
        else if (location == "calendar") {
            this.setState({calendar:!this.state.calendar});
        }
        else if (location == "start-over") {
            this.startOver(true);
        }
    }

    /*
    openChat() {
        if (!this.widgetOpen) {
            toggleWidget(); // does not call toggle handler
            this.widgetOpen = true;
            this.agent.current.show();//fadeIn();
        }
    }

    closeChat() {
        if (this.widgetOpen) {
            toggleWidget(); // does not call toggle handler
            this.widgetOpen = false;
            this.agent.current.hide();//fadeOut();
        }
    }
    */

    /* The old way
    setupAgent() {
        // start the character
        this.agent = new ReactCharacterApiClient("myAgent", {
            width: 750,
            height: 600,
            animateEndpoint: API_BASE + "animate",
            catalogEndpoint: API_BASE + "animate",
            character: "IOFRachelHead",
            density: 3,
            version: "0.1",
            format: "png",
            idleData: {"normal":["idle1-18"]},
            cache: 54124,
            visible: false
        });
        document.getElementById("myAgent").addEventListener("characterLoaded", ()=>{
            this.agentLoaded = true;
        });
        document.getElementById("myAgent").addEventListener("scriptCommand", (e)=>{
            this.handleCommand(e.detail);
        });
        document.getElementById("myAgent").addEventListener("playComplete", (e)=>{
            if (this.agent.current.visible()) this.startIdle();
        });
    }
    */

    // called on initial size and on browser window resize
    resizeAgent() {
        var cx = window.innerWidth;
        var cy = window.innerHeight;
        var rx = cx/750;
        var ry = cy/600;
        var r = Math.min(rx,ry);

        if (!this.agent.current) return;
        let agentdiv = findDOMNode(this.agent.current);
        if (!agentdiv) return;
        agentdiv.style.transform = 'scale('+r+')';
        agentdiv.style.left = '-120px';
        agentdiv.style.bottom = '100vh';
    }

    resizeFrame() {
        var cx = window.innerWidth;
        var cy = window.innerHeight;

        // app itself is a 936x520 virtual space that is stretched to the window width
        var rx = cx/936;
        var div = document.getElementById("frame");
        if (!div) return;
        div.style.transform = 'scale(' + rx + ')';
        div.style.transformOrigin = 'top left';
        div.style.left = '0';
        div.style.top = '0';
    }

    handleHomeClick() {
        this.setState({calendar:false}, ()=>{
            this.resizeFrame();
        });
    }

    toggle(status) {
        this.widgetOpen = status;
        if (status) 
            this.agent.current.show();
        else
            this.agent.current.hide();//fadeOut();
    }

    reply(input) {
        this.agent.current.stop();
        let retract = this.renderRetract();
        let assert = this.renderAssert();
        let xhr = new XMLHttpRequest();
        xhr.open('GET', API_BASE + "reply?userid=" + this.userid + "&input=" + encodeURIComponent(input) + "&retract=" + encodeURIComponent(retract) + "&assert=" + encodeURIComponent(assert), true);
        xhr.onload = () => {
            let ret = JSON.parse(xhr.response);
            if (ret.output) this.handleChatResult(ret.output);
            this.nextIdle = ret.idle;
        };
        xhr.send();
    }

    handleChatResult(output) {
        let transcript = this.agent.current.transcriptFromText(output);
        /*
        if (this.widgetOpen) {
            let script = this.scriptFromText(output);
            this.command = "";
            for (var i = 0; i < script.length; i++) {
                if (script[i].and == "command") 
                    this.command = script[i].type;  // simple mechanism assumes at most one command, to align with first apogee
                this.agent.current.dynamicPlay(script[i]); // Transcript mechanism will be used to reveal text when audio begins playing
            }
            addResponseMessage(transcript);
        }
        else {
            addResponseMessage(transcript);
        }
        */
        this.stopIdle();
        let script = this.agent.current.scriptFromText(output);
        this.command = "";
        for (var i = 0; i < script.length; i++) {
            this.agent.current.dynamicPlay(script[i]); // Transcript mechanism will be used to reveal text when audio begins playing
        }
    }

    handleCommand(command) {
        let api = this.calendar.current?.getApi();
        if (command == "start-over")
            this.startOver(false);
        else if (command == "close")
            this.close();
        else if (command.split(' ')[0] == "name-on-plate")
            this.nameOnPlate(command.split(' ')[1], command.split(' ')[2]); 
        else if (command == "open-contact")
            this.setState({contact:true});
        else if (command == "close-contact")
            this.setState({contact:false});
        else if (command == "open-calendar")
            this.setState({slide1:false,menu:false,calendar:true});
        else if (command == "close-calendar")
            this.setState({calendar:false});
        else if (command == "next-week" && api)
            api.next();
        else if (command == "previous-week" && api)
            api.prev();
        else if (command == "slide1")
            this.setState({menu:false,slide1:true});
        else if (command == "hide-slides")
            this.setState({slide1:false});
        else if (command == "menu")
            this.setState({slide1:false,menu:true});
        else if (command == "hide-menu")
            this.setState({menu:false});

        else if (command == "select-expert-systems")
            this.setState({radio:1});
        else if (command == "select-conversational-ai")
            this.setState({radio:2});
        else if (command == "select-organizational-transformation")
            this.setState({radio:3});
        else if (command == "select-customer-acquisition")
            this.setState({radio:4});
        else if (command == "select-other")
            this.setState({radio:5});
    }

    nameOnPlate(first, last) {
        this.setState({env: {...(this.state.env||{}), first, last}});
    }

    handleSubmitComplete() {
        this.reply("[contact-submitted]");
        this.setState({contact:false});
        this.resetIdle();
    }   

    startOver(fromUI) {
        this.setState({calendar:false, contact:false, env:null, slide1:false, menu:false, radio:undefined});
        this.started = false;
        if (fromUI) {
            this.agent.current.stop();
            this.reply("[start-over]"); // this command is directed at our server and clears the session
            this.resetIdle();
        }
        this.agent.current.hide();//fadeOut();
        this.stopIdle();
    }

    close() {
        this.agent.current.hide();//fadeOut();
        this.setState({calendar:false, contact:false});
        this.stopIdle();
        // but we are started in this case
    }

    // Idle support

    startIdle() {
        this.stopIdle();
        this.idleTimeout = setTimeout(this.onIdle.bind(this), 1000); // one second timer
        this.idleCount = 0;
        this.nextIdle = undefined;
    }

    stopIdle() {
        if (this.idleTimeout) {
            clearTimeout(this.idleTimeout);
            this.idleTimeout = 0;
        }
        this.idleCount = 0;
        this.nextIdle = undefined;
    }

    resetIdle() {
        this.idleCount = 0;
    }

    onIdle() {
        this.idleCount++;
        //console.log(this.idleCount); // For debugging
        this.idleTimeout = setTimeout(this.onIdle.bind(this), 1000); // one second timer
        if (this.idleCount == this.nextIdle) 
            this.reply("[idle " + this.idleCount + "]");
    }
    
    // Cookie support
    
    getCookie(name) {
        let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    setCookie(name, value, days) {
        let d = new Date();
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toUTCString();
    }

    // Mic support

    startStopRecording() {
        this.setState({placeholder:""});
        if (!this.recognizing) {
            if (!this.recognition) {
                this.recognition = new window["webkitSpeechRecognition"]();
                this.recognition.continuous = true;
                this.recognition.interimResults = true;
            }
            this.recognition.onstart = () => {
                this.final_transcript = "";
                this.setState({input:''});
                this.recognizing = true;
                this.setupSpinner();
            };
            this.recognition.onerror = (event) => {
                console.error(event.error);
            };
            this.recognition.onend = () => {
                this.recognizing = false;
            };
            this.recognition.onresult = (event) => {
                var interim_transcript = '';
                if (typeof(event.results) == 'undefined') {
                    this.recognition.onend = null;
                    this.recognition.stop();
                    console.error('mic not supported')
                    return;
                }
                for (var i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        this.final_transcript += event.results[i][0].transcript;
                    } else {
                        interim_transcript += event.results[i][0].transcript;
                    }
                }
                if (interim_transcript) {
                    this.setState({input:interim_transcript});
                }
                else {
                    this.setState({input:this.final_transcript});
                    this.recognizing = false;
                    this.recognition.stop();
                    setTimeout(this.handleChatSubmit.bind(this), 250); // final lingers briefly before sumbmit
                }
            };
            this.recognition.start();
        } 
        else {
            this.recognizing = false;
            if (this.recognition) this.recognition.stop();
        }
    }
    
    closeRecognition() {
        this.recognizing = false;
        if (this.recognition) {
            this.recognition.stop();
            this.recognition = null;
        }
    }
    
    // Mic spinner
    
    setupSpinner() {
        this.spinner = document.getElementById('micspin');
        this.ctx = this.spinner.getContext('2d');
        this.w = 50;
        this.h = 50;
        this.rad = Math.PI/180;
        this.phi = 2;
        this.size = 25;
        this.r = 0;
        this.animateSpinner();
    }
    
    animateSpinner() {
        if (this.recognizing) requestAnimationFrame(this.animateSpinner.bind(this));
        this.ctx.clearRect(0, 0, this.w, this.h);
        if (!this.recognizing) return;
        this.r += 1;
        this.drawSpinnerCanvas(this.r, 18);
    }
    
    drawSpinnerCanvas(r, num) {
        for (var i = this.size; i >= 0; i--) {
            var x = this.w/2 + num*Math.cos((this.r+i*2)*this.phi*this.rad);
            var y = this.h/2 + num*Math.sin((this.r+i*2)*this.phi*this.rad);
            this.ctx.save();
            this.ctx.beginPath();
            this.ctx.fillStyle = "rgba(255, " + (num%255) + ", " + (num%255) + ", " + 1/(25-i+1) + ")";
            this.ctx.arc(x, y, 3, 0, 2*Math.PI);
            this.ctx.fill();
            this.ctx.restore();
        }
    }
    

}

export default App;

/* Router
//import { Link } from 'react-router-dom';
<MenuItem component={<Link to="/test" />}> Test </MenuItem>
<MenuItem component={<Link to="/documentation" />}> Documentation </MenuItem>
<MenuItem component={<Link to="/calendar" />}> Calendar </MenuItem>
also SubMenu is available                        
*/
    
