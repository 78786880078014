import React, { Component } from 'react';
import { Sidebar, Menu, SubMenu, MenuItem, sidebarClasses, menuClasses } from 'react-pro-sidebar';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import { faFunnelDollar } from '@fortawesome/free-solid-svg-icons'

const theme = "dark";
const themes = {
    dark: {
        sidebar: {
            backgroundColor: '#34312f', //'#0b2948',
            color: '#8ba1b7',
        },
        menu: {
            menuContent: '#082440',
            icon: '#59d0ff',
            hover: {
            backgroundColor: '#00458b',
            color: '#b6c8d9',
            },
            disabled: {
            color: '#3e5e7e',
            },
        },
    },
};
const menuItemStyles = {
    root: {
      fontSize: '13px',
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, 1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
};

// hex to rgba converter
function hexToRgba(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

class SidePanel extends Component {
    
    constructor(props) {
        super(props);
    }  

    render() {
        return (
            <Sidebar
                width={(160/0.8)+"px"}
                collapsed={this.props.collapsed}
                backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
                rootStyles={{
                    color: themes[theme].sidebar.color,
                }}
            >
                {!this.props.collapsed &&
                    <img src="img/iOfficeLogo.png" style={{marginLeft:"15px", marginTop:"10px", width:"170px"}}/>
                }
                {this.props.collapsed &&
                    <img src="img/iOfficeSmall.png" width="65" height="65" style={{marginLeft:"10px", marginTop:"10px"}}/>
                }
                <Menu menuItemStyles={menuItemStyles}>
                    {/*
                    <SubMenu
                        label="Charts"
                        icon={<span style={{color:"yellow"}}><FontAwesomeIcon icon={faStickyNote}/></span>}
                    >
                        <MenuItem> Register User</MenuItem>
                        <MenuItem> Book Appointment</MenuItem>
                        <MenuItem> Get Paid</MenuItem>
                    </SubMenu>
                    */}
                    {/* 
                    <MenuItem icon={<span style={{color:"white"}}><FontAwesomeIcon icon={faUserCheck}/></span>} onClick={this.props.onClick.bind(this, "contact")}> Register User </MenuItem>
                    <MenuItem icon={<span style={{color:"white"}}><FontAwesomeIcon icon={faCalendarCheck}/></span>} onClick={this.props.onClick.bind(this, "calendar")}> Book Appointment </MenuItem>
                    <MenuItem icon={<span style={{color:"white"}}><FontAwesomeIcon icon={faFunnelDollar}/></span>}> Get Paid </MenuItem>
                    <div style={{height:"100vw"}}></div>
                    */}
                    <br/>
                    <Button style={{marginLeft:"30px"}} variant="secondary" onClick={this.props.onClick.bind(this, "start-over")}>Start Over</Button>
                    <div style={{height:"100vw"}}></div>
                </Menu>
            </Sidebar>
        );
    }

    componentDidMount() {
    }

}

export default SidePanel;
