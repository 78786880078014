import React, { Component } from 'react';
import { InputGroup, Form, Button } from "react-bootstrap";

//const API_BASE = "http://localhost:3000/";
const API_BASE = "https://ioffice.live/api/";

class ContactModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            first:"",
            last:"",
            email:"",
            notes:""
        };
        this.firstRef = React.createRef();
    }  

    render() {
        return (
            <Form>
                <h1>Contact Signup</h1>
                <Form.Group>
                    <Form.Label>First:</Form.Label>
                    <Form.Control ref={this.firstRef} type="input" value={this.state.first} onChange={(e)=>this.setState({first:e.target.value})}/>
                </Form.Group>
                <Form.Group style={{marginTop:"5px"}}>
                    <Form.Label>Last:</Form.Label>
                    <Form.Control type="input" value={this.state.last} onChange={(e)=>this.setState({last:e.target.value})}/>
                </Form.Group>
                <Form.Group style={{marginTop:"5px"}}>
                    <Form.Label>Email address:</Form.Label>
                    <Form.Control type="email" value={this.state.email} onChange={(e)=>this.setState({email:e.target.value})}/>
                </Form.Group>
                <Form.Group style={{marginTop:"5px"}}>
                    <Form.Label>Notes:</Form.Label>
                    <Form.Control as="textarea" rows={3} value={this.state.notes} onChange={(e)=>this.setState({notes:e.target.value})}/>
                </Form.Group>
                <Button disabled={!this.state.first || !this.state.last || !this.state.email} style={{marginTop:"15px"}} onClick={this.handleSubmit.bind(this)}>Submit</Button>
            </Form>
        );
    }

    handleSubmit() {
        fetch(API_BASE + "submitContact", {
            method: 'post',
			headers: {'Content-type': 'application/x-www-form-urlencoded'},
			body: `first=${encodeURIComponent(this.state.first)}&last=${encodeURIComponent(this.state.last)}&email=${encodeURIComponent(this.state.email)}&notes=${encodeURIComponent(this.state.notes)}`
        }).then(response => response.json()).then(data => { 
            this.props.submitCompleteHandler();
        }).catch(e => {
            console.log(e.message||"no service");
        });
    }

    componentDidMount() {
        this.firstRef.current.focus();
    }

}

export default ContactModal;
