import React, { Component } from 'react';
import { InputGroup, Form, Button } from "react-bootstrap";

class TopBar extends Component {
    
    constructor(props) {
        super(props);
    }  

    render() {
        return (
            <div style={{position:"relative"}}>
                <div style={{position:"absolute", left:"10px", top:"4px", width:"100px", color:"gray", fontFamily:"Arial", fontWeight:"bold", fontSize:"25px"}}>Demo&nbsp;Console</div>
                {/*
                <InputGroup style={{position:"absolute", left:"300px", top:"3px", width:"300px"}} size="sm" className="mb-3">
                    <InputGroup.Text id="inputGroup-sizing-sm">Search: </InputGroup.Text>
                    <Form.Control
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                    />
                </InputGroup>
                */}
            </div>
        );
    }

    componentDidMount() {
    }

}

export default TopBar;
